// Here you can add other styles
.nav-tabs {
  border-bottom: #fff;
}

.navbar-brand {
  font-size: 2rem;
  font-weight: 1000;
  font-family: Arial, Helvetica, sans-serif;
  background-image: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  background-color: #6372ff;
  background-clip: text;
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: #fff;
  border-color: #d8dbe0;
  border-bottom-color: #fff;
  position: relative;
  border-bottom-width: 1px;
  z-index: 100;
}

.Toastify__toast--success {
  width: 115%;
  background-color: white !important;
  border-left: 10px solid #2eb85c;
}

.Toastify__toast--warning {
  width: 115%;
  background-color: white !important;
  border-left: 10px solid #f9b115;
}

.Toastify__toast--error {
  width: 115%;
  background-color: white !important;
  border-left: 10px solid #e55353;
}

.Toastify__close-button {
  color: #636f83 !important;
}

.markercluster-map {
  height: 90vh;
}

.overflowHidden {
  overflow: hidden;
}

.card-columns {
  @include media-breakpoint-only(lg) {
    column-count: 3;
  }
  @include media-breakpoint-only(xl) {
    column-count: 4;
  }
}

.modal-header {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  -webkit-border-top-left-radius: 25px;
  -webkit-border-top-right-radius: 25px;
}

#landing {
  &.modal-content {
    border-radius: 25px;
    border: 0;
  }
}

.close {
  color: white;

  &:hover {
    color: white;
  }
}

.border-round-25 {
  border-radius: 25px;
}

.border-top-round-25 {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  -webkit-border-top-left-radius: 25px;
  -webkit-border-top-right-radius: 25px;
}
