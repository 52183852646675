@import "~font-awesome/scss/font-awesome";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body,
html {
  scroll-behavior: smooth;
  font-family: "Open Sans", sans-serif;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  color: #777;
  font-weight: 400;
  width: 100% !important;
  height: 100% !important;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px grey;
    border-radius: 15px;
    margin: 10px 0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #5ca9fb;
    border-radius: 15px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #6372ff;
  }
}

h2,
h3,
h4 {
  font-family: "Raleway", sans-serif;
}
h2 {
  text-transform: uppercase;
  margin: 0 0 20px;
  font-weight: 800;
  font-size: 36px;
  color: #333;
}
h3 {
  font-size: 20px;
  font-weight: 600;
  color: #333;
}
h4 {
  font-size: 18px;
  color: #333;
  font-weight: 600;
}
h5 {
  text-transform: uppercase;
  font-weight: 700;
  line-height: 20px;
}
p {
  font-size: 15px;
}
p.intro {
  margin: 12px 0 0;
  line-height: 24px;
}
a {
  color: #608dfd;
  font-weight: 400;
}
a:hover,
a:focus {
  text-decoration: none;
  color: #608dfd;
}
ul,
ol {
  list-style: none;
}
ul,
ol {
  padding: 0;
  webkit-padding: 0;
  moz-padding: 0;
}
hr {
  height: 2px;
  width: 70px;
  text-align: center;
  position: relative;
  background: #1e7a46;
  margin-bottom: 20px;
  border: 0;
}
/* Navigation */
#header {
  a {
    color: white;
  }
}
#menu {
  padding: 15px;
  transition: all 0.8s;
}
#menu.navbar-default {
  background-color: #fff;
  border-color: rgba(231, 231, 231, 0);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}
#menu a.navbar-brand {
  font-family: "Raleway", sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: #333;
  text-transform: uppercase;
}
#menu.navbar-default .navbar-nav > li > a {
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  color: #555;
  font-size: 15px;
  font-weight: 400;
  padding: 8px 2px;
  border-radius: 0;
  margin: 9px 20px 0;
}
#menu.navbar-default .navbar-nav > li > a:after {
  display: block;
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 0;
  height: 2px;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  content: "";
  transition: width 0.2s;
}
#menu.navbar-default .navbar-nav > li > a:hover:after {
  width: 100%;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  background-color: transparent;
}
.navbar-default .navbar-nav > .active > a:after,
.navbar-default .navbar-nav > .active > a:hover:after,
.navbar-default .navbar-nav > .active > a:focus:after {
  display: block !important;
  position: absolute !important;
  left: 0 !important;
  bottom: -1px !important;
  width: 100% !important;
  height: 2px !important;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%) !important;
  content: "" !important;
  transition: width 0.2s !important;
}
.navbar-toggle {
  border-radius: 0;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #fff;
  border-color: #608dfd;
}
.navbar-default .navbar-toggle:hover > .icon-bar {
  background-color: #608dfd;
}
.section-title {
  margin-bottom: 3rem;
}
.section-title h2 {
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.section-title h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: -30px;
  left: 50%;
}
.section-title p {
  font-size: 18px;
}
.btn-custom {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  color: #fff;
  background-color: #5ca9fb;
  background-image: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  padding: 14px 34px;
  letter-spacing: 1px;
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  border-radius: 25px;
  transition: all 0.5s linear;
  border: 0;
}
.btn-custom:hover,
.btn-custom:focus,
.btn-custom.focus,
.btn-custom:active,
.btn-custom.active {
  color: #fff;
  background-image: none;
  background-color: #6372ff;
}
.select-custom {
  @extend .btn-custom;
  padding: 0.5rem 34px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.05);
  background-image: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);

  &:hover,
  &:focus,
  &:active {
    background-image: linear-gradient(to right, #6372ff 0%, #5ca9fb 50%);
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 0.4rem;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    //box-shadow: inset 0 0 5px grey;
    border-radius: 5rem;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #6372ff;
    border-radius: 5rem;
  }
}
.btn:active,
.btn.active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
a:focus,
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
  //outline-offset: none;
}
.btn-login {
  font-size: 1.5rem;
  font-weight: 1000;
  font-family: Arial, Helvetica, sans-serif;
  background-image: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  background-color: #6372ff;
  background-clip: text;
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  &:hover {
    cursor: pointer;
  }
}
/* Header Section */
.intro {
  display: table;
  width: 100%;
  padding: 0;
  background: url(../assets/images/bg.jpeg) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}
.intro .overlay {
  background: rgba(0, 0, 0, 0.2);
}
.intro h1 {
  font-family: "Raleway", sans-serif;
  color: #fff;
  font-size: 4em;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 10px;
}
.intro h1 span {
  font-weight: 800;
  color: #5ca9fb;
}
.intro p {
  color: #fff;
  font-size: 22px;
  font-weight: 300;
  line-height: 30px;
  margin: 0 auto;
  margin-bottom: 60px;
}
header .intro-text {
  padding: 13rem 0;
  width: 100%;
  text-align: center;
}
/* About Section */
#about {
  padding: 6rem 0;
  background: #f7fbfc;
}
#about h3 {
  font-size: 22px;
  margin: 0 0 20px;
}
#about h2 {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
#about h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 0;
}
#about .about-text li {
  margin-bottom: 6px;
  margin-left: 6px;
  list-style: none;
  padding: 0;
}
#about .about-text li:before {
  content: "\f00c";
  font-family: "FontAwesome", serif;
  color: #5ca9fb;
  font-size: 11px;
  font-weight: 300;
  padding-right: 8px;
}
#about img {
  border-radius: 25px;
  width: 100%;
  margin-top: 10px;
  background: #fff;
  border-right: 0;
}
#about p {
  line-height: 24px;
  margin: 30px 0;
}
/* Services Section */
#services {
  padding: 6rem 0;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  color: #fff;
}
#services .service-desc {
  margin: 10px 10px 20px;
}
#services h2 {
  color: #fff;
}
#services .section-title h2::after {
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.3);
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: -30px;
  left: 50%;
}
#services i.fa {
  font-size: 42px;
  width: 120px;
  height: 120px;
  padding: 40px 0;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  border-radius: 50%;
  color: #fff;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.05);

  &:hover {
    cursor: pointer;
    background: linear-gradient(to right, #6372ff 0%, #5ca9fb 50%);
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
    transition: all 0.1s linear;
  }

  &.checked {
    padding: 37px 0;
    border: 3px solid white;
    background: linear-gradient(to right, #6372ff 0%, #5ca9fb 50%);
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
  }
}
#services h3 {
  font-weight: 500;
  padding: 5px 0;
  color: #fff;
}
#services p {
  color: rgba(255, 255, 255, 0.75);
}
#services .service-desc {
  margin-bottom: 40px;
}
/* Portfolio Section */
#portfolio {
  padding: 100px 0;
}

.portfolio-item {
  margin: 1px -15px 0 -14px;
  padding: 0;

  .hover-bg {
    overflow: hidden;
    position: relative;

    .hover-text {
      cursor: pointer;
      position: absolute;
      text-align: center;
      margin: 0 auto;
      color: #fff;
      background: linear-gradient(
        to right,
        rgba(99, 114, 255, 0.8) 0%,
        rgba(92, 169, 251, 0.8) 100%
      );
      height: 100%;
      width: 100%;
      opacity: 0;
      transition: all 0.5s;
      padding-top: 20%;

      > h4 {
        opacity: 0;
        color: #fff;
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        transition: all 0.3s;
        font-size: 14px;
        letter-spacing: 1px;
        font-weight: 500;
        text-transform: uppercase;
      }

      > span {
        opacity: 0;
        color: #fff;
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        transition: all 0.3s;
        font-size: 11px;
        letter-spacing: 1px;
        font-weight: 500;
      }
    }

    :hover {
      .hover-text {
        opacity: 1;

        > h4 {
          opacity: 1;
          -webkit-backface-visibility: hidden;
          -webkit-transform: translateY(0);
          transform: translateY(0);
        }

        > span {
          opacity: 1;
          -webkit-backface-visibility: hidden;
          -webkit-transform: translateY(0);
          transform: translateY(0);
        }
      }
    }
  }
}

/* Testimonials Section */
#testimonials {
  padding: 100px 0;
  background: #f6f6f6;
}
#testimonials i {
  color: #e6e6e6;
  font-size: 32px;
  margin-bottom: 20px;
}
.testimonial {
  position: relative;
  padding: 20px;
}
.testimonial-image {
  float: left;
  margin-right: 15px;
}
.testimonial-image,
.testimonial-image img {
  display: block;
  width: 64px;
  height: 64px;
  border-radius: 50%;
}
.testimonial-content {
  position: relative;
  overflow: hidden;
}
.testimonial-content p {
  margin-bottom: 0;
  font-size: 14px;
  font-style: italic;
}
.testimonial-meta {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 600;
  color: #666;
}
/* Team Section */
#team {
  padding: 100px 0;
}
#team h4 {
  margin: 5px 0;
}
#team .team-img {
  width: 240px;
}
#team .thumbnail {
  background: transparent;
  border: 0;
}
#team .thumbnail .caption {
  padding: 10px 0 0;
  color: #888;
}
/* Contact Section */
#contact {
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  color: rgba(255, 255, 255, 0.75);
}
#contact .section-title {
  margin-bottom: 20px;
}
#contact .section-title p {
  font-size: 16px;
}
#contact h2 {
  color: #fff;
  padding-top: 3.5rem;
  margin-top: 0.6rem;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
}
#contact .section-title h2::after {
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.3);
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 30px;
}
#contact h3 {
  color: #fff;
  margin-bottom: 25px;
  padding-bottom: 20px;
  font-weight: 400;
}
#contact .text-danger {
  color: #cc0033;
  text-align: left;
}
#contact .btn-custom {
  margin: 30px 0;
  background: transparent;
  border: 2px solid #fff;
}
#contact .btn-custom:hover {
  color: #1f386e;
  background: #fff;
}
label {
  font-size: 12px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  float: left;
}
#contact .form-control {
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857143;
  color: #444;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ddd;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}
#contact .form-control:focus {
  border-color: #999;
  outline: 0;
  -webkit-box-shadow: transparent;
  box-shadow: transparent;
}
.form-control::-webkit-input-placeholder {
  color: #777;
}
.form-control:-moz-placeholder {
  color: #777;
}
.form-control::-moz-placeholder {
  color: #777;
}
.form-control:-ms-input-placeholder {
  color: #777;
}
#partners {
  .nothing-found {
    justify-content: center;
    padding-bottom: 25px;
    display: inline-grid;

    .nothing-found-svg {
      height: 300;
      margin: 0 auto;
    }

    p {
      font-size: 40px;
      font-weight: 700;
      font-family: "Raleway", "Open Sans", sans-serif;
      text-align: center;
      background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
#contact .contact-item {
  margin: 20px 0;
}
#contact .contact-item span {
  color: rgba(255, 255, 255, 1);
  margin-bottom: 10px;
  display: block;
}
#contact .contact-item i.fa {
  margin-right: 10px;
}
#contact .social {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  padding-top: 50px;
  margin-top: 50px;
  text-align: center;
}
#contact .social ul li {
  display: inline-block;
  margin: 0 20px;
}
#contact .social i.fa {
  font-size: 22px;
  width: 48px;
  height: 48px;
  padding: 12px 0;
  border: 2px solid #fff;
  color: #fff;
  border-radius: 50%;
  transition: all 0.3s;
}
#contact .social i.fa:hover {
  color: #608dfd;
  background: #fff;
}
/* Footer Section*/
#footer {
  background: #f6f6f6;
  padding: 30px 0;
}
#footer p {
  color: #888;
  font-size: 14px;
}
#footer a {
  color: #608dfd;
}
#footer a:hover {
  border-bottom: 2px solid #608dfd;
}

@media (max-width: 768px) {
  header {
    .intro-text {
      padding: 7rem 0;
      font-size: 0.85em;
    }
  }

  #services {
    padding: 2rem 0;

    .nowrap.row {
      flex-wrap: nowrap;
    }

    i.fa {
      font-size: 28px;
      width: 80px;
      height: 80px;
      padding: 27px 0;

      &.checked {
        padding: 24px 0;
      }
    }

    h3 {
      font-size: 1em;
    }

    .col-md-4 {
      width: 33.33%;
    }
  }

  #about {
    padding: 1rem 0;

    img {
      margin: 50px 0;
    }
  }

  #contact h2 {
    padding-top: 1rem;
  }
}
/* Language Select Section */
$max-of-langs: 4;
.flag {
  height: 2em !important;
  width: 2em !important;
  border-radius: 35%;
  position: absolute;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  top: -0.8rem;

  @for $i from 2 through $max-of-langs {
    &.translate#{$i} {
      transform: translate(0, ($i - 1) * 2.5em) scale(0.8);
    }
  }
}
/* Authentication Modals */
.auth-icon {
  width: 1rem;
}
.new-account-text {
  padding-top: 1rem;
  cursor: pointer;
  font-size: 1.5em;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  font-weight: 500;

  // &:hover {
  //   color: black;
  // }
}

.service-filters {
  background-color: white;
  border-radius: 4rem;
}

// when navbar collapses
@media screen and (max-width: 992px) {
  .flag {
    @for $i from 2 through $max-of-langs {
      &.translate#{$i} {
        transform: translate(($i - 1) * 2.5em) scale(0.8);
      }
    }
  }

  .service-filters {
    border-radius: 2rem;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}
@media screen and (max-width: 400px) {
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 100%;
  }

  #portfolio {
    width: 100%;
  }
}
#basic-nav-dropdown {
  padding-left: 0;
}
.dropdown-item {
  &:active {
    background: none;
  }
}
#username-dropdown,
#name-lang-wrapper {
  padding: 0;
}
