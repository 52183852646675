@use "sass:math";

@mixin m-overlay-content {
  .overlay-content {
    display: flex !important;

    .btn-custom {
      font-family: sans-serif;
      color: #fff;
      // mix-blend-mode: screen;
      background-color: rgba(0, 0, 0, 0);
      background-image: none;
      padding: 0.6rem 2rem;
      font-size: 0.9em;
      font-weight: 700;
      border-radius: 25px;
      transition: none;
      border: 2px solid #fff;
    }

    .btn-custom:hover,
    .btn-custom:focus,
    .btn-custom.focus,
    .btn-custom:active,
    .btn-custom.active {
      color: #000;
      background-color: #fff;
      outline: none;
      box-shadow: none;
    }
  }
}

@mixin m-card-img-overlay {
  opacity: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  transition: 0.5s ease;
  background: rgba(0, 0, 0, 0.5);
}

@mixin info-card-slide-x($x-translate-percentage) {
  transform: translatex($x-translate-percentage) scale(0.8);
  opacity: 0.4;
  z-index: 0;
}

@mixin info-carousel($num-of-images) {
  @for $i from 1 through $num-of-images {
    // hide images that are behind
    @for $j from $i through $num-of-images - 2 {
      @if not($i == 1 and $j + 2 == $num-of-images) {
        #item-#{$i}:checked ~ .info-cards #img-#{$j + 2},
        #item-#{$j + 2}:checked ~ .info-cards #img-#{$i} {
          opacity: 0;
          z-index: 0;
        }
      }
    }

    // image slide left and right (non-edge cases)
    @if $i < $num-of-images {
      #item-#{$i + 1}:checked ~ .info-cards #img-#{$i} {
        @include info-card-slide-x(-40%);
      }

      #item-#{$i}:checked ~ .info-cards #img-#{$i + 1} {
        @include info-card-slide-x(40%);
      }
    } @else {
      // image slide left and right (edge cases)
      #item-1:checked ~ .info-cards #img-#{$num-of-images} {
        @include info-card-slide-x(-40%);
      }

      #item-#{$num-of-images}:checked ~ .info-cards #img-1 {
        @include info-card-slide-x(40%);
      }
    }

    // when image is selected
    #item-#{$i}:checked ~ .info-cards #img-#{$i} {
      transform: translatex(0) scale(1);
      opacity: 1;
      z-index: 1;

      img {
        box-shadow: 0 0 5px 0 rgba(81, 81, 81, 0.47);
      }
    }
  }
}

.card-img-overlay {
  border-radius: 23px;
  color: white;

  &:hover {
    height: 100%;
    @include m-card-img-overlay;
    @include m-overlay-content;
  }
}

.modal-header .close {
  box-shadow: none;
  outline: none;
}

.overlay-content {
  display: none !important;
}

.card-footer:last-child {
  border: none !important;
  border-radius: 25px !important;
}

.card-img-top {
  width: 100%;
  height: 100% !important;
  height: 14rem;
}

.partner-card {
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.04);
    border: none;
    box-shadow: 0 0.5rem 1rem rgb(0 0 21 / 30%);
  }
}

.fa-star,
.fa-star-half-o,
.fa-star-o {
  color: #ffc300;
}

@media (pointer: coarse) {
  .card-img-overlay {
    height: 55%;
    @include m-card-img-overlay;
    @include m-overlay-content;
    border-radius: 23px 23px 0 0;

    .overlay-content {
      .btn-custom {
        padding: 0.6rem 3rem;
      }
    }

    &:hover {
      height: 55%;
    }
  }
}

@media only screen and (pointer: coarse) and (orientation: portrait) and (max-width: 490px) {
  #partnerCard.modal-body {
    top: 2.5rem;
  }
}

//*********** Info *****************
input[type="radio"] {
  display: none;
}

.info-card {
  position: absolute;
  width: 50%;
  height: 100%;
  left: 0;
  right: 0;
  margin: auto;
  transition: transform 0.4s ease;
  cursor: pointer;
}

#partner-page {
  background-color: white;
  padding-top: 4rem;
}

.col-container {
  padding-top: 1rem;
}

.info-container {
  width: 100%;

  // max-width: 800px;
  // max-height: 600px;
  // height: 480px;
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.info-cards {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 20rem;
  margin-bottom: 20px;
}

.arrow-icons-container {
  justify-content: space-between;
  position: absolute;
  top: 42%;
  left: 10%;
  width: 80%;
  pointer-events: none;
}

.arrow-icon {
  font-weight: 700 !important;
  color: white;
  font-size: 3em !important;
  padding: 0 1.5rem;
  z-index: 1;
  pointer-events: none;
}

.partner-page-title {
  font-size: 2.2em;

  &.partner {
    font-size: 1.8em;
  }
}

.info-icons-col {
  font-size: 0.9em;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  width: fit-content !important;
}

.partner-page-info-icon {
  color: gray;
  font-size: 0.8rem;
}

.gallery-item {
  width: 100%;
  height: 100%;
  border-radius: 23px;
  object-fit: cover;
}

.partner-info-img {
  width: 100%;
  height: 25%;
  border-radius: 23px;
  object-fit: cover;
}

$max-of-images: 7;
@for $i from 1 through $max-of-images {
  .max-num-of-images-#{$i} {
    @include info-carousel($i);
  }
}
